<template class="container">
  <div class="row">
    <div class="col-md">
      <!-- <img
        alt="Vue logo"
        src="../assets/logo.png"
        style="width: 100%; height: auto"
      /> -->
      <br />
      <h2>Welcome to RSB electrical internal portal</h2>
      <br />
      <h4>
        Please choose one of the available options from the top-right menu
      </h4>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
  };
</script>

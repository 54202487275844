import { createStore } from 'vuex';

import authModule from './auth.js';

export default createStore({
	state: {},
	actions: {},
	mutations: {},
	getters: {},
	modules: {
		auth: authModule,
	},
});

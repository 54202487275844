import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from 'axios';

// console.log(
// 	'1.' + window.location.protocol,
// 	'2.' + window.location.hostname,
// 	'3.' + window.location.pathname,
// 	'4.' + window.location.hash
// );

if (window.location.href.indexOf('localhost') >= 0) {
	axios.defaults.baseURL = 'http://localhost/rsb-portal_backEnd';
	// } else if (window.location.href.indexOf('4data.co.nz') >= 0) {
	// 	axios.defaults.baseURL = 'https://backendapp.4data.co.nz/';
	// } else if (window.location.href.indexOf('rsbelec.co.nz') >= 0) {
	// 	axios.defaults.baseURL = 'https://backendapp.rsbelec.co.nz/';
	// } else {
} else {
	// eliminam subweb din numele site-ului
	const host = window.location.hostname.substring(
		window.location.hostname.indexOf('.') + 1
	);
	axios.defaults.baseURL = window.location.protocol + '//backendapp.' + host;
}
// console.log('backendapp:', axios.defaults.baseURL);

import VueAxios from 'vue-axios';

import VueSignaturePad from 'vue-signature-pad';

const app = createApp(App);

// import curlirize from 'axios-curlirize';
// // initializing axios-curlirize with your axios instance
// curlirize(axios);

app.use(store);
app.use(router);
app.use(VueSignaturePad);

// setari pentru axios
app.use(VueAxios, axios);
// pentru a putea folosi this.axios peste tot in aplicatie
store.axios = app.axios;

app.mount('#app');

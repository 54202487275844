// timer care face autologin cat timp este logat utilizatorul si daca a expirat timpul pt token
let timer;

import router from '../router/index.js';

export default {
	state: {
		apiURL: 'https://rsbelectrical.simprosuite.com',
		tokenURL: 'https://rsbelectrical.simprosuite.com/oauth2/token',
		client_id: 'b51733fce7f41a7fa5d2d2ec89ea83',
		client_secret: '2644ea4eac',
		userIsLogedIn: false,
		userName: null,
		loginResponse: {
			access_token: null,
			expires_in: null,
			token_type: null,
			scope: null,
			refresh_token: null,
		},
	},
	actions: {
		// facem logarea in sistemul simPro
		async loginUser(context, payload) {
			return await context.dispatch('auth', {
				...payload, // se ia payload-ul initial la care se adauga ce urmeaza
				mode: 'login',
			});
		},
		// facem autologarea
		async tryLogin(context) {
			const userName = localStorage.getItem('userName');
			const rememberMe = localStorage.getItem('rememberMe');
			const access_token = localStorage.getItem('access_token');
			const refresh_token = localStorage.getItem('refresh_token');
			const tokenExpiration = localStorage.getItem('tokenExpiration');

			const expiresIn = +tokenExpiration - new Date().getTime();

			if (expiresIn <= 0) {
				// oprirea timerului care face autologin dupa expirarea tokenului
				clearInterval(timer);
				context.dispatch('logoutUser');
				// redirectionare catre login ('/login')
				router.replace('/login');
			} else {
				// se creaza timerul care sa faca autologin la momentul expirarii tokenului
				timer = setInterval(function () {
					context.dispatch('tryLogin');
					console.log(
						'se cere tryLogin peste: ' + expiresIn / 1000 + ' secunde'
					);
				}, expiresIn);
			}

			if (userName && access_token && refresh_token) {
				await context.dispatch('auth', {
					userName: userName,
					rememberMe: rememberMe,
					mode: 'autoLogin',
					refresh_token: refresh_token,
				});
			} else {
				await context.dispatch('logoutUser');
			}
		},
		// facem autentificarea
		async auth(context, payload) {
			try {
				// se construieste obiectul trimis pentru logare
				const dataSent = new FormData();
				// partea comuna pentru logare si autologare(refresh la token)
				dataSent.append('client_id', context.rootGetters.clientId); // citim client_id
				dataSent.append('client_secret', context.rootGetters.clientSecret); // citim client_secret
				// daca se cere logarea
				if (payload.mode == 'login') {
					// daca se cere login
					dataSent.append('grant_type', 'password');
					dataSent.append('username', payload.userName);
					dataSent.append('password', payload.password);
				}
				// daca se cere autologarea
				else if (payload.mode == 'autoLogin') {
					// daca se cere refresh la access_token
					dataSent.append('grant_type', 'refresh_token');
					dataSent.append('refresh_token', payload.refresh_token);
				}
				// eroare daca se ajunge aici insa fara logare sau autologare
				else {
					throw new Error('Unauthorized attempt to login! Watch it!');
				}

				// afisam toate parechile key-value din dataSent (FormData)
				// for (var pair of dataSent.entries()) {
				// 	console.log(pair[0] + ': ' + pair[1]);
				// }
				// se transmit datele catre simPro pentru logare
				const response = await this.axios({
					method: 'post',
					url: context.rootGetters.tokenUrl, // citim tokenURL
					data: dataSent,
				});
				const responseData = response.data;
				// console.log('response: ', response);
				// console.log('tokenUrl a trimis inapoi: ', responseData);

				// daca raspunsul este ok
				if (response.status === 200) {
					// convertim expires_in din secunde in milisecunde
					const expiresIn = +responseData.expires_in * 1000;
					const expirationDate = new Date().getTime() + expiresIn;
					// console.log('Login token expires in: ', expirationDate);

					// setam implicit variabilele in locaStorage
					localStorage.setItem('rememberMe', payload.rememberMe);
					localStorage.setItem('userName', payload.userName);
					localStorage.setItem('access_token', responseData.access_token);
					localStorage.setItem('refresh_token', responseData.refresh_token);
					localStorage.setItem('tokenExpiration', expirationDate);

					// oprirea timerului care face autologin dupa expirarea tokenului
					clearTimeout(timer);
					// se incearca autologarea dupa ce a expirat timpul pt tokenul curent
					timer = setTimeout(function () {
						context.dispatch('tryLogin');
					}, expiresIn);

					// transmitem datele utilizatorului (numele si faptul ca e logat)
					context.commit('setUser', {
						userName: payload.userName,
						userIsLogedIn: true,
					});

					// transmitem datele primite in urma logarii (ceea ce a transmis simPro inapoi)
					context.commit('setLoginResponse', responseData);

					return 'ok';
				} else {
					throw new Error(
						response.statusText ||
							'There was a problem at logging in, please try agaian later.'
					);
				}
			} catch (error) {
				console.log(error);
				const errorMsg =
					error.message == 'Request failed with status code 401'
						? 'Check your cerdentials & try again.'
						: error.message || 'Something went wrong, come back later.';
				throw new Error(errorMsg);
			}
		},
		// facem delogarea utilizatorului curent
		async logoutUser(context) {
			// stergem datele din localStorage
			localStorage.removeItem('refresh_token');
			localStorage.removeItem('access_token');
			localStorage.removeItem('tokenExpiration');

			// oprirea timerului care face autologin dupa expirarea tokenului
			clearInterval(timer);

			// resetam datele primite de la server
			await context.commit('setLoginResponse', {
				access_token: null,
				expires_in: null,
				token_type: null,
				scope: null,
				refresh_token: null,
			});
			// resetam datele utilizatorului logat
			await context.commit('setUser', {
				userName: localStorage.getItem('userName'),
				userIsLogedIn: false,
				rememberMe: localStorage.getItem('rememberMe'),
			});
		},
	},
	mutations: {
		// modificam datele de logare primite de la SimPro
		async setLoginResponse(state, payload) {
			state.loginResponse = payload;

			// setam headerul pentru axios
			const accessToken = this.getters.loginData['access_token'];
			// console.log('accessToken: ', accessToken);
			this.axios.defaults.headers.common['Authorization'] =
				'Bearer ' + (accessToken || '');
			// cream headerele obligatorii
			this.axios.defaults.headers.common['Content-Type'] = 'aplication/json';
		},
		// modificam numele utilizatorului si statusul logarii
		async setUser(state, payload) {
			state.userName = payload.userName;
			state.userIsLogedIn = payload.userIsLogedIn;

			// daca utilizatorul nu mai este logat
			if (payload.userIsLogedIn === false) {
				// eliminam informatiile din localStorage
				localStorage.removeItem('refresh_token');
				localStorage.removeItem('access_token');

				// daca utilizatorul nu a cerut s afie retinut
				const rememberMe = JSON.parse(payload.rememberMe);
				if (rememberMe === false) {
					localStorage.removeItem('userName');
					localStorage.removeItem('rememberMe');
				}
			}
		},
	},
	getters: {
		// returnam userName-ul cu care s-a logat
		userName(state) {
			return state.userName;
		},
		// returnam datele pe care le primim la logarea in sistem
		loginData(state) {
			return state.loginResponse;
		},
		// returnam apiURL-ul aferent aplicatiei noastre
		apiURL(state) {
			return state.apiURL;
		},
		// returnam tokenURL-ul aferent aplicatiei noastre
		tokenUrl(state) {
			return state.tokenURL;
		},
		// returnam client_id - ul pentru accesarea datelor aferente
		clientId(state) {
			return state.client_id;
		},
		// returnam client_secret aferent client_id-ului pentru care accesam datele
		clientSecret(state) {
			return state.client_secret;
		},
		// returnam statusul utilizatorului (daca e logat sau nu)
		isLoggedIn(state) {
			const userName = localStorage.getItem('userName');
			const access_token = localStorage.getItem('access_token');
			const refresh_token = localStorage.getItem('refresh_token');
			if (userName && access_token && refresh_token) {
				state.userIsLogedIn = true;
			} else {
				state.userIsLogedIn = false;
			}

			return state.userIsLogedIn;
		},
	},
};

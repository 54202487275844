<template>
  <!-- Navbar -->
  <nav class="navbar fixed-top" style="background-color: #bfedbf">
    <div class="container-fluid" style="background-color: inherit">
      <!-- logo cu link catre home -->
      <router-link to="/" class="navbar-brand">
        <img
          src="../../assets/logo.png"
          alt="RSB-portal"
          height="35"
          class="d-inline-block align-top"
        />
      </router-link>
      <!-- numele utilizatorului logat & buton de Logout -->
      <button type="button" class="btn btn-dark" v-if="isLoggedIn" disabled>
        <i class="bi bi-person-square"></i> {{ userLoggedIn }}
      </button>
      <!-- BUTONUL PENTRU MENU-L AFERENT -->
      <button
        v-if="isLoggedIn"
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        Menu <i class="bi bi-list"></i>
      </button>
      <!-- MENIUL IN SINE -->
      <div
        v-if="isLoggedIn"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
          <!-- BUTONUL CARE INCHIDE MENIUL -->
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            ref="btnCloseMenu"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li
              v-for="page in pagesList"
              :key="page.name"
              class="nav-item bg-outline-success"
            >
              <router-link
                :to="page.path"
                class="btn btn-outline-success m-1"
                :class="{
                  active: currentPath === page.path,
                  disabled: !page.available,
                }"
                @click="toggleNavbar"
              >
                {{ page.name }}
              </router-link>
            </li>
            <li class="nav-item bg-outline-dark">
              <button
                type="button"
                class="btn btn-dark"
                v-if="isLoggedIn"
                @click="logoutUser"
              >
                Logout <i class="bi bi-box-arrow-up-right"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      pagesList: [
        {
          path: "/ta",
          name: "Task Analysis",
          available: true,
        },
        {
          path: "/coc",
          name: "Certificate of Compliance",
          available: true,
        },
        {
          path: "/esc",
          name: "Electrical Safety Certificate",
          available: false,
        },
        {
          path: "/dws",
          name: "Day Work Sheet",
          available: false,
        },
      ],
      currentPageUrl: "",
    };
  },
  methods: {
    // functia care actioneaza "click" buotnului care inchide meniul
    toggleNavbar() {
      this.$refs.btnCloseMenu.click();
    },
    async logoutUser() {
      await this.toggleNavbar();
      await this.$store.dispatch("logoutUser");
      // redirectionare catre login ('/login')
      this.$router.replace("/login");
    },
  },
  computed: {
    currentPath() {
      return this.currentPageUrl.path;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    userLoggedIn() {
      return this.$store.getters.userName;
    },
  },
  mounted() {
    this.currentPageUrl = this.$router.currentRoute;
  },
};
</script>

<style></style>

import { createRouter, createWebHistory } from 'vue-router';

import Home from '../pages/Home.vue';

import store from '../store/index.js';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: { requiresAuth: true },
		children: [],
	},
	{
		path: '/login',
		name: 'Login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../pages/LogIn.vue'),
		meta: { requiresUnauth: true },
	},
	{
		path: '/ta',
		name: 'TaskAnalysis',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../pages/TaskAnalysis.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/coc',
		name: 'COC',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../pages/COC.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/esc',
		name: 'ESC',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../pages/ESC.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/dws',
		name: 'DayWokSheet',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../pages/DayWorkSheet.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/:notFound(.*)',
		component: () =>
			import(/* webpackChunkName: "about" */ '../pages/PageNotFound.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(function (to, from, next) {
	// console.clear();
	// console.log('isLoggedIn: ' + store.getters.isLoggedIn);
	// console.log('to: ', to);
	// console.log('from: ', from);
	// console.log('next: ', next);

	// daca se cere a fi logat si userul nu e logat
	if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
		// daca avem redirectionare atunci atasam link-ul
		to.path.substring(1).length > 0
			? next('/login?redirect=' + to.path.substring(1))
			: next('/login');
	}
	// daca se cere sa nu fie logat si e logat
	else if (to.meta.requiresUnauth && store.getters.isLoggedIn) {
		next('/');
	} else {
		next();
	}
});

export default router;
